<template>
  <!-- 重拍 -->
  <div v-if="dataVal.flag" class="mask">
    <div class="mask-main">
      <div class="mask-header">
        <div class="mask-left">
          {{ dataVal.title }}
        </div>
        <i @click="handleClose" class="el-icon-close posi"></i>
      </div>
      <div class="mask-line"></div>
      <div class="mask-table">
        <div class="mask-lang">门店列表</div>
        <el-table
          class="center_table"
          max-height="500"
          ref="multipleTable"
          :data="importArr"
          tooltip-effect="dark"
          style="width: 100%"
          border
          :header-cell-style="{
            width: 1401 + 'px',
            height: 4.416 + 'vh',
            background: '#F9F9F9',
            fontSize: 16 + 'px',
            textAlign: 'center',
          }"
        >
          <el-table-column
            prop="storename"
            align="center"
            label="门店名称"
            show-overflow-tooltip
            min-width="167px"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="invitecode"
            label="门店地址"
            show-overflow-tooltip
            min-width="125px"
          >
            <template slot-scope="scope">
              <div>{{ scope.row.storeaddr.split("/").join("")+scope.row.detailaddr }}</div>
            </template></el-table-column
          >
          <el-table-column
            align="center"
            prop="bianhao"
            label="操作"
            min-width="186px"
          >
            <template slot-scope="scope">
              <span class="aFu_span" @click="handleSubmit(scope.row.id)"
                >关联</span
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "substancesCell",
  props: ["dataVal"],
  data() {
    return {
      importArr: [],
      id: "",
    };
  },
  mounted() {},
  methods: {
    //关闭弹窗
    handleClose() {
      let obj = {
        flag: false,
        submitFlag: false,
      };
      // console.log(obj);
      this.$emit("brokerinfoCell", obj);
    },
    async childGetstore() {
      let res = await this.$api.childGetstore();
      if (res.code == 1) {
        this.importArr = res.data;
      }
    },
    handleSubmit(id) {
      let obj = {
        flag: false,
        submitFlag: true,
        id: id,
      };
      this.$emit("brokerinfoCell", obj);
    },
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newVal, oldVal) {
        this.childGetstore();
      },
    },
  },
};
</script>

<style lang="less" scoped>
@import url("@/assets/css/maskCap.less");
</style>