var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dataVal.flag)?_c('div',{staticClass:"mask"},[_c('div',{staticClass:"mask-main"},[_c('div',{staticClass:"mask-header"},[_c('div',{staticClass:"mask-left"},[_vm._v(" "+_vm._s(_vm.dataVal.title)+" ")]),_c('i',{staticClass:"el-icon-close posi",on:{"click":_vm.handleClose}})]),_c('div',{staticClass:"mask-line"}),_c('div',{staticClass:"mask-table"},[_c('div',{staticClass:"mask-lang"},[_vm._v("门店列表")]),_c('el-table',{ref:"multipleTable",staticClass:"center_table",staticStyle:{"width":"100%"},attrs:{"max-height":"500","data":_vm.importArr,"tooltip-effect":"dark","border":"","header-cell-style":{
          width: 1401 + 'px',
          height: 4.416 + 'vh',
          background: '#F9F9F9',
          fontSize: 16 + 'px',
          textAlign: 'center',
        }}},[_c('el-table-column',{attrs:{"prop":"storename","align":"center","label":"门店名称","show-overflow-tooltip":"","min-width":"167px"}}),_c('el-table-column',{attrs:{"align":"center","prop":"invitecode","label":"门店地址","show-overflow-tooltip":"","min-width":"125px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_vm._v(_vm._s(scope.row.storeaddr.split("/").join("")+scope.row.detailaddr))])]}}],null,false,369894283)}),_c('el-table-column',{attrs:{"align":"center","prop":"bianhao","label":"操作","min-width":"186px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"aFu_span",on:{"click":function($event){return _vm.handleSubmit(scope.row.id)}}},[_vm._v("关联")])]}}],null,false,534340123)})],1)],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }