<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 添加监护人 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="cen-table">
        <table>
          <tr>
            <td class="xin2">监护人关系</td>
            <td class="sel">
              <!-- <input v-model="us" type="text"> -->
              <el-select
                :popper-append-to-body="false"
                v-model="users.relation"
                placeholder="请选择监护人关系"
              >
                <el-option
                  v-for="item in options"
                  :key="item.values"
                  :label="item.values"
                  :value="item.values"
                >
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="xin2">监护人姓名</td>
            <td>
              <input
                v-model="users.name"
                placeholder="请输入监护人姓名"
                type="text"
              />
            </td>
          </tr>
          <tr>
            <td class="xin2">监护人电话</td>
            <td>
              <input
                type="text"
                v-model="users.phone"
                placeholder="请输入监护人电话"
              />
            </td>
          </tr>
          <tr>
            <td class="">年龄</td>
            <td>
              <input
                type="number"
                v-model="users.age"
                placeholder="请输入年龄"
              />
            </td>
          </tr>
          <tr>
            <td class="xin2">通讯地址</td>
            <td class="sel">
              <el-cascader
                size="large"
                :props="{ label: 'label', value: 'label' }"
                :options="optionsaddr"
                v-model="selectedOptions"
                @change="handleChangeAddr"
                placeholder="请选择所在城市"
              >
              </el-cascader>
            </td>
          </tr>
          <tr>
            <td class="">详细地址</td>
            <td>
              <input
                type="text"
                v-model="addrDetail"
                placeholder="请输入详细地址"
              />
            </td>
          </tr>
          <tr>
            <td class="">职业</td>
            <td class="sel">
              <el-select v-model="users.work" placeholder="请选择职业">
                <el-option
                  v-for="item in optionszy"
                  :key="item.id"
                  :label="item.values"
                  :value="item.values"
                >
                </el-option>
              </el-select>
            </td>
          </tr>
        </table>
      </div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">
          {{ dataVal.btns }}
        </button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
          {{ dataVal.btnq }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
//引入element-china-area-data实现三级联动地址
import { regionData, pcTextArr } from "element-china-area-data";
export default {
  name: "AddGuardian",
  props: ["dataVal"],
  data() {
    return {
      users: {
        relation: "", //监护人关系
        name: "", //姓名
        phone: "",
        model_id: "",
        id: "",
        age: "", //年龄
        address: "", //地址
        work: "", //职业
      },
      options: [],
      type: "add",
      model_id: "",
      id: "",
      //选择所在城市
      optionsaddr: regionData,
      selectedOptions: [],
      addrDetail: "",
      infoForm: {
        province: "",
        city: "",
      },
      optionszy: [],
      textarea: "",
    };
  },
  mounted() {
    this.dictionaryGetguardian();
    this.dictionaryGetwork();
  },
  methods: {
    dictionaryGetguardian() {
      this.$api.dictionaryGetguardian().then((res) => {
        if (res.code == 1) {
          this.options = res.data;
        }
      });
    },
    dictionaryGetwork() {
      this.$api.dictionaryGetwork().then((res) => {
        if (res.code == 1) {
          this.optionszy = res.data;
        }
      });
    },
    //地址
    handleChangeAddr() {
      // console.log(this.selectedOptions);
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        submitFlag: false,
      };
      this.$emit("AdduardianCell", obj);
    },
    handleSubmit() {
      this.users.address =
        this.selectedOptions.join("/") + "," + this.addrDetail;
      console.log(this.users);
      let reg = /^1[3456789]\d{9}$/;
      if (this.users.relation == "") {
        this.$message({
          message: "请选择监护人关系",
          type: "error",
        });
        return false;
      }
      if (this.users.name == "") {
        this.$message({
          message: "请输入监护人姓名",
          type: "error",
        });
        return false;
      }
      if (this.users.phone == "") {
        this.$message({
          message: "请输入监护人电话",
          type: "error",
        });
        return false;
      }
      if (!reg.test(this.users.phone)) {
        this.$message({
          message: "监护人电话格式不正确",
          type: "error",
        });
        return false;
      }
      //   let obj = {};
      //添加·
      //   if (this.type == "add") {
      //     obj = { ...this.users, model_id: this.model_id };
      //   } else if(this.type=="edit") {
      //     //修改
      //     obj = { ...this.users, model_id: this.model_id,id:this.id };
      //   }
      //添加或修改监护人
      this.$api.childEditguardian({ ...this.users }).then((res) => {
        if (res.code == 1) {
          this.users.id = res.data;
          let obj = {
            flag: false,
            submitFlag: true,
            users: this.users,
          };
          this.$emit("AdduardianCell", obj);
        }
      });
    },
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.type == "add") {
          this.users = {
            relation: "", //监护人关系
            name: "", //姓名
            phone: "",
            model_id: "",
            id: "",
          };
        } else if (newVal.type == "edit") {
          this.users = { ...newVal.item };
          this.addrDetail = this.users.address.split(",")[1];
          this.selectedOptions = this.users.address.split(",")[0].split("/");
        }
      },
    },
  },
};
</script>

<style lang="less">
.cen-table {
  tr {
    .sel {
      .el-input__inner {
        height: 4.259vh;
        border: none;
        width: 19.791vw;
        font-size: 0.83vw;
        color: #000;
        padding-left: 1.041vw;
      }
    }
  }
}
</style>
<style lang="less" scoped>
@import url("@/assets/css/table.css");
@import url("@/assets/css/mask.less");

.mask-main {
  width: auto !important;
}

.cen-table {
  width: auto;
  margin-top: 0.788vh;

  tr {
    width: auto;
    height: 4.416vh;
    text-align: center;
    display: flex;
    background-color: #ffffff;
    border: 0.0789vh solid #e6e6e6;

    td:nth-child(1) {
      width: 7.5vw;
      // flex: 1;
      font-family: MicrosoftYaHei-Bold;
      font-size: 0.73vw;
      font-weight: normal;
      letter-spacing: 0.05vw;
      color: #333333;
      background-color: #f9f9f9;
      line-height: 4.416vh;
      border-right: 0.052vw solid #e6e6e6;
      font-weight: bold;
    }

    td:nth-child(2) {
      input {
        width: 19.791vw;
        height: 100%;
        border: 0;
        outline: none;
        box-sizing: border-box;
        padding: 0 1.041vw;
        font-size: 0.83vw;
      }
    }
  }
}

.mask-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.76vh;

  button {
    width: 7.81vw;
    height: 3.627vh;
    border-radius: 0.31vw;
    font-size: 0.73vw;
    cursor: pointer;
    background: #fff;
    border: solid 0.05vw #999999;
    color: #999999;
    margin-right: 3.13vw;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-bg {
    background: #6d79ff;
    border: 0.0789vh solid #6d79ff;
    color: #fff;
  }
}
</style>