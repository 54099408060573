import { post, get, request } from "./http.js";

//接口案例
export const getCouponDetail = (data) => post("/coupondetail", data);

// 登录接口
const indexLogin = (data) => post("index/login", data);
//生成扫码登录二维码
const indexSetqrcode = (data) => post("index/setqrcode", data);
//首页
const indexIndex = (data) => post("index/index", data);
//上传图片
const uploadimg = (data) => post("index/uploadimg", data);
//上传文件
const uploadfile = (data) => post("index/uploadfile", data);
//登录日志
const indexLoginlog = (data) => post("index/loginlog", data);
//得到账户信息
const indexGetinfo = (data) => post("index/getinfo", data);
//得到账户信息
const adminResetpassword = (data) => post("admin/resetpassword", data);

//童模库
//童模列表
const childModellist = (data) => post("child/modellist", data);
//童模详情
const childModeldetail = (data) => post("child/modeldetail", data);
//童模新增编辑
const childModeledit = (data) => post("child/modeledit", data);
//检测邀请码是否存在
const childCheckinvitecode = (data) => post("child/checkinvitecode", data);
//新增、编辑监护人信息
const childEditguardian = (data) => post("child/editguardian", data);
//删除监护人信息
const childDelguardian = (data) => post("child/delguardian", data);
//当前童模的作品展示列表
const childListawards = (data) => post("child/listawards", data);
//新增、编辑作品展示
const childEditawards = (data) => post("child/editawards", data);
//删除作品展示
const childDelawards = (data) => post("child/delawards", data);
//新增、编辑才艺特长
const childEditskill = (data) => post("child/editskill", data);
//得到所有经纪人
const childGetbroker = (data) => post("child/getbroker", data);
//重新关联
const childAssociationbroker = (data) => post("child/associationbroker", data);
//删除才艺特长
const childDelskill = (data) => post("child/delskill", data);
//当前童模的才艺特长列表
const childListskill = (data) => post("child/listskill", data);
//待提交童模数据提交审核
const childSubexamine = (data) => post("child/subexamine", data);
//审核中童模撤回审核
const childRecallexamine = (data) => post("child/recallexamine", data);
//删除、批量删除童模
const childDelmodel = (data) => post("child/delmodel", data);
//已入库童模 编辑首推标签
const childEditmodellabel = (data) => post("child/editmodellabel", data);
//操作记录列表
const childOperatelog = (data) => post("child/operatelog", data);
//童模审核列表
const childExaminelist = (data) => post("child/examinelist", data);
//审核
const childSetexamine = (data) => post("child/setexamine", data);
//判断当前审核员是否最后一个审核人员
const childCheckexaminerole = (data) => post("child/checkexaminerole", data);
//得到某个童模的审核记录
const childGetexaminelog = (data) => post("child/getexaminelog", data);
//童模标签 (才艺特长) 列表
const childGetchildlabel = (data) => post("child/getchildlabel", data);
//童模标签 新增编辑
const childEditchildlabel = (data) => post("child/editchildlabel", data);
//童模标签 删除
const childDelchildlabel = (data) => post("child/delchildlabel", data);
//童模标签批量导入
const childImportchildlabel = (data) => post("child/importchildlabel", data);

//通告列表
//通告列表
const informInfolist = (data) => post("inform/infolist", data);
//通告详情
const informInfodetail = (data) => post("inform/infodetail", data);
//新增编辑通告
const informInfoedit = (data) => post("inform/infoedit", data);
//新增演员需求
const informEditperformer = (data) => post("inform/editperformer", data);
//删除演员需求
const informDelperformer = (data) => post("inform/delperformer", data);
//发布，批量发布
const informInforelease = (data) => post("inform/inforelease", data);
//作废、批量作废
const informInfocancel = (data) => post("inform/infocancel", data);
//设置通告 中单 批量中单
const informInfoselected = (data) => post("inform/infoselected", data);
//暂停、批量暂停
const informInfopause = (data) => post("inform/infopause", data);
//暂停中的通告 操作 继续、批量继续
const informInfocontinue = (data) => post("inform/infocontinue", data);
//结束通告、批量结束
const informInfoend = (data) => post("inform/infoend", data);
//报名的童模、复选中的童模、中单的童模、系统推荐的童模
const informSignuplist = (data) => post("inform/signuplist", data);
//设置报名记录中单
const informSignupselected = (data) => post("inform/signupselected", data);
//设置报名记录复选
const informSignuprenew = (data) => post("inform/signuprenew", data);
//设置报名记录 移除复选
const informSignupdelrenew = (data) => post("inform/signupdelrenew", data);
//设置报名记录 移除中单
const informSignupdelselected = (data) => post("inform/signupdelselected", data);
//系统推荐童模 移除童模
const informDelrecommend = (data) => post("inform/delrecommend", data);
//操作记录
const informOperatelog = (data) => post("inform/operatelog", data);
//审核列表
const informExaminelist = (data) => post("inform/examinelist", data);
//审核 单个或批量
const informSetexamine = (data) => post("inform/setexamine", data);
//得到某个通告的审核记录
const informGetexaminelog = (data) => post("inform/getexaminelog", data);
//审核中通告 撤回审核 单个/批量
const informRecallexamine = (data) => post("inform/recallexamine", data);
// 作废通告 单个批量恢复
const informRestoreinform = (data) => post("inform/restoreinform", data);
// 删除通告 单个或批量
const informDelinform = (data) => post("inform/delinform", data);
// 系统推荐的童模 推送消息模板给童模
const informRemindsign = (data) => post("inform/remindsign", data);

//广告管理
//广告列表
const adLst = (data) => post("ad/lst", data);
//新增编辑信息
const adEdit = (data) => post("ad/edit", data);
//批量、单个删除
const adDel = (data) => post("ad/del", data);
//批量、单个启用 禁用
const adSetstatus = (data) => post("ad/setstatus", data);

//反馈投诉
//反馈投诉列表
const complaintLst = (data) => post("complaint/lst", data);
//填写处理进程
const complaintSethandle = (data) => post("complaint/sethandle", data);
//查看某条订单的评价处理记录
const complaintShowhandle = (data) => post("complaint/showhandle", data);

//用户管理
//用户管理列表
const userLst = (data) => post("user/lst", data);
//用户管理详情
const userDetail = (data) => post("user/detail", data);
//用户管理 批量、单个启用 禁用
const userSetstatus = (data) => post("user/setstatus", data);
//用户管理 批量、单个删除
const userDel = (data) => post("user/del", data);

//功能授权
//功能授权 角色及对应权限菜单
const authorizeLst = (data) => post("authorize/lst", data);
//功能授权 得到所有的系统权限
const authorizeGetallmenu = (data) => post("authorize/getallmenu", data);
//功能授权 新增编辑角色
const authorizeEdit = (data) => post("authorize/edit", data);
//功能授权 删除角色
const authorizeDel = (data) => post("authorize/del", data);
//功能授权 给相应角色分配菜单权限
const authorizeSetmenu = (data) => post("authorize/setmenu", data);

//子账号管理
//子账号管理 列表
const adminLst = (data) => post("admin/lst", data);
//子账号管理 新增编辑信息
const adminEdit = (data) => post("admin/edit", data);
//子账号管理 生成邀请码
const adminSetinvitecode = (data) => post("admin/setinvitecode", data);
//子账号管理 批量、单个删除
const adminDel = (data) => post("admin/del", data);
//子账号管理 批量、单个启用 禁用
const adminSetstatus = (data) => post("admin/setstatus", data);
//子账号管理 得到可选角色
const adminGetrole = (data) => post("admin/getrole", data);
//子账号管理 得到可选角色
const adminRebind = (data) => post("admin/rebind", data);

//数据字典
//参数名列表
const dictionaryParamlist = (data) => post("dictionary/paramlist", data);
//参数值列表
const dictionaryValuelist = (data) => post("dictionary/valuelist", data);
//新增编辑参数值信息
const dictionaryEditvalue = (data) => post("dictionary/editvalue", data);
//数据字典 批量、单个删除
const dictionaryDel = (data) => post("dictionary/del", data);
//数据字典 批量、单个启用 禁用
const dictionarySetstatus = (data) => post("dictionary/setstatus", data);
//得到年龄
const dictionaryGetage = (data) => post("dictionary/getage", data);
//得到身高
const dictionaryGethigh = (data) => post("dictionary/gethigh", data);
//得到国籍
const dictionaryGetnationality = (data) => post("dictionary/getnationality", data);
//得到作品名称
const dictionaryGetmatch = (data) => post("dictionary/getmatch", data);
//得到首推标签
// const dictionaryGetlabel = (data) => post("dictionary/getlabel", data);
//得到通告类型
const dictionaryGetinformtype = (data) => post("dictionary/getinformtype", data);
//得到广告类型
const dictionaryGetadtype = (data) => post("dictionary/getadtype", data);
//得到投诉类型
const dictionaryGetcomplainttype = (data) => post("dictionary/getcomplainttype", data);
//得到监护人关系
const dictionaryGetguardian = (data) => post("dictionary/getguardian", data);
//得到通告来源
const dictionaryGetinformsrc = (data) => post("dictionary/getinformsrc", data);
//得到报名要求
const dictionaryGetsigncondition = (data) => post("dictionary/getsigncondition", data);
//得到所属大区
const dictionaryGetarea = (data) => post("dictionary/getarea", data);

//审批流程设置
//审批流程设置 列表
const processLst = (data) => post("process/lst", data);
//审批流程设置 新增、编辑
const processEdit = (data) => post("process/edit", data);

//系统美化
//系统美化 历史记录列表
const configLst = (data) => post("config/lst", data);
//系统美化 得到目前正在使用的参数
const configGetmakevalue = (data) => post("config/getmakevalue", data);
//系统美化 编辑参数
const configEdit = (data) => post("config/edit", data);
//系统美化 重新启用某条参数
const configRenewmake = (data) => post("config/renewmake", data);
//系统美化 删除记录
const configDellog = (data) => post("config/dellog", data);

//消息设置
//消息设置 列表
const messageLst = (data) => post("message/lst", data);
//消息设置 编辑
const messageEdit = (data) => post("message/edit", data);

//星粉俱乐部
//得到职业
const dictionaryGetwork = (data) => post("dictionary/getwork", data);
//得到所有门店
const childGetstore = (data) => post("child/getstore", data);
//门店列表
const childAdminLst = (data) => post("admin/lst", data);
//生成分享二维码
const childSetsharecode = (data) => post("child/setsharecode", data);
//重新关联门店
const childAssociation = (data) => post("child/association", data);
//新增编辑通告
const informEdit = (data) => post("inform/edit", data);
//通告列表
const informLst = (data) => post("inform/lst", data);
//单个、批量删除
const informDel = (data) => post("inform/del", data);
//交付产品 列表
const deliverLst = (data) => post("deliver/lst", data);
//交付产品 新增编辑
const deliverEdit = (data) => post("deliver/edit", data);
//交付产品 是否交付
const deliverSetstatus = (data) => post("deliver/setstatus", data);
//得到交付产品下拉数据
const dictionaryGetproduct = (data) => post("dictionary/getproduct", data);
//除、批量删除交付产品
const deliverDel = (data) => post("deliver/del", data);
//得到通告标签
const dictionaryGetlabel = (data) => post("dictionary/getlabel", data);
export default {
    deliverDel,
    dictionaryGetlabel,
    //星粉俱乐部
    dictionaryGetwork,
    childGetstore,
    childAdminLst,
    childSetsharecode,
    informEdit,
    informLst,
    informDel,
    dictionaryGetproduct,
    deliverLst,
    deliverSetstatus,
    deliverEdit,
    childAssociation,
    //
    indexLogin,
    indexSetqrcode,
    indexIndex,
    uploadimg,
    uploadfile,
    indexLoginlog,
    adminResetpassword,

    childModellist,
    childModeldetail,
    childModeledit,
    childCheckinvitecode,
    childEditguardian,
    dictionaryGetguardian,
    childDelguardian,
    childListawards,
    childEditawards,
    childDelawards,
    childEditskill,
    childDelskill,
    childListskill,
    childSubexamine,
    childRecallexamine,
    childDelmodel,
    childOperatelog,
    childExaminelist,
    childSetexamine,
    childGetexaminelog,
    childEditmodellabel,
    childGetbroker,
    childAssociationbroker,

    childGetchildlabel,
    childEditchildlabel,
    childDelchildlabel,
    childCheckexaminerole,
    childImportchildlabel,
    //通告列表
    informInfolist,
    informInfodetail,
    informInfoedit,
    informEditperformer,
    informDelperformer,
    informInforelease,
    informInfocancel,
    informInfoselected,
    informInfopause,
    informInfocontinue,
    informInfoend,
    informSignuplist,
    informSignupselected,
    informSignuprenew,
    informSignupdelrenew,
    informSignupdelselected,
    informDelrecommend,
    informOperatelog,
    informExaminelist,
    informSetexamine,
    informGetexaminelog,
    informRecallexamine,
    informRestoreinform,
    informDelinform,
    informRemindsign,
    
    //广告管理
    adLst,
    adEdit,
    adDel,
    adSetstatus,

    //反馈投诉
    complaintLst,
    complaintSethandle,
    complaintShowhandle,
    
    //用户管理
    userLst,
    userDetail,
    userSetstatus,
    userDel,

    //功能授权
    authorizeLst,
    authorizeGetallmenu,
    authorizeEdit,
    authorizeDel,
    authorizeSetmenu,

    //子账号管理
    adminLst,
    adminEdit,
    adminSetinvitecode,
    adminDel,
    adminSetstatus,
    adminGetrole,
    adminRebind,

    //数据字典
    dictionaryParamlist,
    dictionaryValuelist,
    dictionaryEditvalue,
    dictionaryDel,
    dictionarySetstatus,
    dictionaryGetage,
    dictionaryGethigh,
    dictionaryGetnationality,
    dictionaryGetmatch,
    dictionaryGetlabel,
    dictionaryGetinformtype,
    dictionaryGetcomplainttype,
    dictionaryGetadtype,
    dictionaryGetinformsrc,
    dictionaryGetsigncondition,
    dictionaryGetarea,

    //审批流程设置
    processLst,
    processEdit,

    //系统美化
    configLst,
    configGetmakevalue,
    configEdit,
    configRenewmake,
    configDellog,

    //消息设置
    messageLst,
    messageEdit,
    indexGetinfo,
















}