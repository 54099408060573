<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 新增 作品展示 才艺特长  -->
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ dataVal.title1 }}
        </div>
      </div>
      <div class="voucher input-item-right">
        <div style="display: flex">
          <!-- 使用element-ui自带样式 -->
          <ul
            class="el-upload-list el-upload-list--picture-card"
            id="imgUrlList2"
            :style="{
              width: imgUrlList2.length == 0 ? 'auto !important' : '54vw',
            }"
          >
            <li
              v-for="(item, index) in imgUrlList2"
              :key="index"
              class="el-upload-list__item is-success animated"
            >
              <img
                :src="urls + item"
                alt=""
                class="el-upload-list__item-thumbnail"
              />
              <i class="el-icon-close"></i>
              <span class="el-upload-list__item-actions">
                <!-- 预览 -->
                <span
                  class="el-upload-list__item-preview"
                  @click="handlePictureCardPreviewFileDetail(item)"
                >
                  <i class="el-icon-zoom-in"></i>
                </span>
                <!-- 删除 -->
                <span
                  class="el-upload-list__item-delete"
                  @click="handleRemoveFileDetail(index)"
                >
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </li>
          </ul>
          <el-upload
            class="uoloadSty"
            ref="uploadDetail"
            multiple
            :limit="15"
            :action="urls + '/api/platform/index/uploadimg'"
            :headers="headers"
            list-type="picture-card"
            :file-list="imgUrlList"
            :show-file-list="false"
            :on-success="handleImgListSuccess"
            :on-progress="handleImgListProgress"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </div>

        <!-- <el-upload class="upload-demo" :action="urls + '/api/platform/index/uploadimg'" :headers="headers"
          :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
          :on-progress="handleProgress">
          <div v-if="!obj.image" class="avatar_i">
            <img src="@/assets/images/icon/photo.png" />
            <div>上传参演经历</div>
          </div>
          <video width="100%" v-else-if="obj.image.split('.')[obj.image.split('.').length-1]=='mp4'||obj.image.split('.')[obj.image.split('.').length-1]=='mkv'||obj.image.split('.')[obj.image.split('.').length-1]=='mov'" :src="urls + obj.image"></video>
          <img v-else class="image-has" :src="urls + obj.image" alt="" />
        </el-upload>
        <div v-if="percentage != 0" class="flex-pro">
          <el-progress :percentage="percentage" status="success"></el-progress>
        </div> -->
      </div>

      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          {{ dataVal.title2 }}
        </div>
      </div>
      <div>
        <!-- <el-select :popper-append-to-body="false" v-model="obj.name" placeholder="请选择作品名称">
          <el-option v-for="item in options" :key="item.values" :label="item.values" :value="item.values">
          </el-option>
        </el-select> -->
        <el-input placeholder="请输入活动名称" v-model="obj.name"></el-input>
      </div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">
          {{ dataVal.btns }}
        </button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
          {{ dataVal.btnq }}
        </button>
      </div>
    </div>
    <!-- 预览弹出层 -->
    <el-dialog :visible.sync="dialogVisibleDetail">
      <img width="100%" :src="dialogImageDetailUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import data from "@/utils/filter/data";
let loading = null;
export default {
  name: "AddTaltent",
  props: ["dataVal"],
  data() {
    return {
      headers: {
        token: localStorage.getItem("token"),
      },
      urls: this.imgurl,
      options: [],
      obj: {
        image: "",
        name: "",
      },
      percentage: 0,
      //上传商品图片
      dialogImageDetailUrl: "",
      dialogVisibleDetail: false,
      disabled: false,
      headers: {
        token: window.localStorage.getItem("token"),
      },
      imgUrlList: [],
      imgUrlList2: [],
      //预览图
      imageUrl: "",
    };
  },
  mounted() {
    console.log(this.data);
  },
  methods: {
    //上传商品图片
    handleImgListSuccess(response, file, fileList) {
      loading.close();
      this.imgUrlList2.push(response.data.imgsrc);
      // console.log(this.imgUrlList2);
    },
    //上传中
    handleImgListProgress(response, file, fileList) {
      loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.6)",
      });
      // console.log(JSON.stringify(this.imgUrlList2), i);
    },
    // 放大
    handlePictureCardPreviewFileDetail(file) {
      this.dialogImageDetailUrl = this.urls + file;
      this.dialogVisibleDetail = true;
      setTimeout(() => {
        this.$nextTick(() => {
          document.getElementsByClassName("v-modal")[0].style.display = "none";
        })
      },50)

       
    },
    // 删除
    handleRemoveFileDetail(index) {
      this.imgUrlList2.splice(index, 1);
    },
    beforeAvatarUpload(file) {},
    //取消弹窗
    handleRefund() {
      let obj = {
        negativeFlag: false,
        submitFlag: false,
      };
      this.$emit("AdduardianCell", obj);
    },
    handleSubmit() {
      this.obj.image = this.imgUrlList2.toString();
      let obj = {
        negativeFlag: false,
        submitFlag: true,
        obj: this.obj,
      };
      this.$emit("AdduardianCell", obj);
    },
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newVal, oldVal) {
        this.options = newVal.list;
        if (newVal.type == "edit") {
          this.obj.image = newVal.obj.image.split(",");
          this.imgUrlList2 = newVal.obj.image.split(",");
          this.obj.name = newVal.obj.name;
        } else {
          this.obj.image = "";
          this.obj.name = "";
        }
      },
    },
  },
};
</script>
<style>
.voucher .el-upload {
  width: 148px !important;
  height: 148px !important;
}
.el-upload-list--picture-card .el-upload-list__item-actions {
  opacity: 0 !important;
}
.el-upload-list--picture-card .el-upload-list__item-actions:hover {
  opacity: 1 !important;
}
</style>
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
.voucher {
  align-items: flex-start;
  justify-content: flex-start;
}
/deep/.input-item-right {
  width: 350px;
  position: relative;
  .el-input__inner {
    background-color: #f9f9f9;
    height: 50px;
    line-height: 50px;
  }
  .el-select {
    width: 100%;
  }
  .el-textarea__inner {
    background-color: #f9f9f9;
    height: 100px;
  }
  .posi-money {
    position: absolute;
    z-index: 1;
    left: 19px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
    color: #333;
    font-weight: 500;
  }
}
.flex-pro {
  position: absolute;
  width: 18.75vw;
  bottom: -1.578vh;
  bottom: -1.578vh;
  left: 50%;
  transform: translateX(-50%);
}

.avatar_i {
  div {
    font-size: 0.73vw;
    margin-top: 1.577vh;
    color: #aaa;
  }
}

/deep/.el-input__inner {
  width: 100% !important;
  height: 4.416vh !important;
  background-color: #f9f9f9 !important;
  border: solid 0vw #e6e6e6 !important;
}

/deep/.el-select {
  width: 100%;
}

.save {
  margin: 0 !important;
}

.table > div:nth-child(2) {
  font-family: MicrosoftYaHei;
  font-size: 0.73vw;
  color: #ff0000;
  margin-top: 0.71vh;
}

.table > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2.839vh;
}

.mask-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.76vh;

  button {
    width: 7.81vw;
    height: 3.627vh;
    border-radius: 0.31vw;
    font-size: 0.73vw;
    cursor: pointer;
  }

  button {
    background-color: #ffffff;
    border: solid 0.05vw #999999;
    color: #999999;
    margin-right: 3.13vw;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-bg {
    background: #6d79ff;
    border: 0.0789vh solid #6d79ff;
    color: #fff;
  }
}

.order-mask .mask-main {
  width: 70vw;
}

.order-mask .mask-main .mask-nav {
  border: 0;
}
.el-upload-list--picture-card {
  max-width: 54vw !important;
  max-height: 40vh;
  overflow-x: auto;
  display: flex;
  flex-wrap: wrap !important;
}
.voucher {
  margin-bottom: 3.155vh;
}
</style>