import Vue from 'vue'
//引入element-ui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//引入vue-router
import router from './router/index'  //刚刚建立的文件夹
import VueRouter from 'vue-router'
import App from './App.vue'
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

//富文本

//引入组件
import Component from '@/utils/index.js'

//时间过滤
import FilDate from '@/utils/filter/data'
Vue.prototype.$FilDate = FilDate;

//引入cookies
import Cookies from 'js-cookie'
Vue.prototype.$cookie = Cookies;

Vue.prototype.imgurl = "https://interface.starka.com.cn";
//全局过滤器 秒
Vue.filter('DateTime', function (e,type=1) {
  let d = new Date(e * 1000);
  let year = d.getFullYear();
  let month = d.getMonth();
  month = month + 1 > 12 ? 1 : month + 1;
  month = month > 9 ? month : "0" + month.toString();
  let day = d.getDate();
  day = day > 9 ? day : "0" + day.toString();
  let hour = d.getHours();
  hour = hour > 9 ? hour : "0" + hour.toString();
  let minute = d.getMinutes();
  minute = minute > 9 ? minute : "0" + minute.toString();
  let second = d.getSeconds();
  second = second > 9 ? second : "0" + second.toString();

  let str = ""
  if (type==1) {
    str = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  }else if(type=="2"){
    str = `${year}年${month}月${day}日`;
  }else if(type=="3"){
    str = `${year}-${month}-${day}`;
  }
  return str
}
)
// 水印指令
Vue.directive('watermark', {
  inserted: function (el, binding) {
    // 获取水印配置
    const text = binding.value.text || 'WATERMARK';
    const color = binding.value.color || 'rgba(0, 0, 0, 0.3)';
    const fontSize = binding.value.fontSize || '16px';
 
    // 创建canvas元素
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = el.offsetWidth;
    canvas.height = el.offsetHeight;
    ctx.font = fontSize + ' Arial, sans-serif';
    ctx.fillStyle = color;
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(-Math.atan(canvas.height / canvas.width));
 
    // 绘制水印
    const measure = ctx.measureText(text);
    const dx = measure.width * -0.5;
    ctx.fillText(text, dx, 0);
    
    // 创建div用于遮盖
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.top = 0;
    div.style.left = 0;
    div.style.right = 0;
    div.style.bottom = 0;
    div.style.pointerEvents = 'none'; // 确保水印不影响元素的交互
    div.style.backgroundImage = 'url(' + canvas.toDataURL('image/png') + ')';
    div.style.backgroundSize = '100% 100%';
 
    // 添加div到DOM
    el.appendChild(div);
  }
});

//引入API
import API from '@/request/api';
Vue.prototype.$api = API;


//使用element-ui
Vue.use(ElementUI);
//使用vue-router
Vue.use(VueRouter)
//全局注册组件
Vue.use(Component)



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
