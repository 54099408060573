<template>
  <div class="header">
    <!-- <img src="../assets/images/head/logo.png" class="img1" alt="" /> -->
    <div class="log">
      <img
        :src="logo ? urls + logo : '../assets/images/head/logo.png'"
        class="img1"
        alt=""
      />
    </div>
    <div class="head-l">
      <div
        class="head-l1"
        :class="{ 'head-l1act': active == i }"
        v-for="(item, i) in list"
        :key="i"
        @click="click(i, item.router)"
      >
        <img :src="item.img" alt="" class="head-img" />
        <!-- <img src="../assets/images/head/a.png" alt=""> -->
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="header-r">
      <div class="head-tongzhi" @click="handleTonotice">
        <img src="../assets/images/icon/tongzhi.png" alt="" />
      </div>
      <el-dropdown @command="command" placement="bottom">
        <div class="header-r">
          <img src="../assets/images/head/toux.png" class="head-r" alt="" />
          <div>{{ useInfo.realname }}</div>
          <img src="../assets/images/head/xiala.png" class="img2" alt="" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="1"> 重置密码 </el-dropdown-item>
          <el-dropdown-item command="2">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <ResetPassword
      :dataVal="pwdObj"
      @resetPasswordCell="resetPasswordCell"
    ></ResetPassword>
  </div>
</template>

<script>
import ResetPassword from "@/components/ResetPassword.vue";
export default {
  name: "Header",
  components: {
    ResetPassword: ResetPassword,
  },
  data() {
    return {
      //重置密码
      pwdObj: {
        flag: false,
        btnq: "取消",
        title: "重置密码",
        btns: "确定",
      },
      // after_time: "",
      useInfo: {},
      list: [],
      list2: [
        {
          id: 1,
          name: "首页",
          router: "/",
          img: require("../assets/images/head/indexw.png"),
        },
        {
          id: 2,
          name: "童模库",
          router: "/childmodelLibrary",
          img: require("../assets/images/head/childmodelLibrary.png"),
        },
        // {
        //   id: 3,
        //   name: "童模运营",
        //   router: "/childmodeloperation",
        //   img: require("../assets/images/head/childmodeloperation.png"),
        // },
        {
          id: 4,
          name: "基础信息",
          router: "/basicInformation",
          img: require("../assets/images/head/basicInformation.png"),
        },
      ],
      active: 0,
      day: 22,
      logo: "",
      urls: this.imgurl,
    };
  },
  created() {
    if (localStorage.getItem("token")) {
      this.useInfo = JSON.parse(localStorage.getItem("userinfo"));
      // this.indexGetinfo()
    }
  },
  mounted() {
    // if (this.$route.path == "/") {
    //   this.active = 0;
    //   return false;
    // }
    if (sessionStorage.getItem("activeId")) {
      this.active = Number(sessionStorage.getItem("activeId"));
    }
    if (localStorage.getItem("token")) {
      this.indexGetinfo();
      this.configGetmakevalue();
      
    }

    // this.indexGetinfo()
  },
  methods: {
    useInfos() {
      let userinfo = window.localStorage.getItem("userinfo");

      if (userinfo) {
        userinfo = JSON.parse(userinfo);
        if (userinfo.role_id == 1) {
          this.list = this.list2;
        } else {
          this.list = [
            {
              id: 1,
              name: "首页",
              router: "/",
              img: require("../assets/images/head/indexw.png"),
            },
            {
              id: 2,
              name: "童模库",
              router: "/childmodelLibrary",
              img: require("../assets/images/head/childmodelLibrary.png"),
            },
          ];
        }
      }
    },
    //重置密码回调
    async resetPasswordCell(data) {
      if (data.submitFlag) {
        this.$message({
          message: "重置密码成功",
          type: "success",
        });
      }
      this.pwdObj.flag = false;
    },
    async configGetmakevalue() {
      let res = await this.$api.configGetmakevalue();
      if (res.code == 1) {
        this.logo = res.data.logo;
      }
    },
    async indexGetinfo() {
      let res = await this.$api.indexGetinfo();
      if (res.code == 1) {
        this.useInfo = res.data;
        localStorage.setItem("userinfo", JSON.stringify(res.data));
        if (this.useInfo.role_id != 1) {
          if (this.useInfo.menu_ids) {
            let arr = [];
            let menu_ids = this.useInfo.menu_ids.split(",");
            this.list2.forEach((item) => {
              if (menu_ids.indexOf(item.id.toString()) != -1) {
                arr.push(item);
              }
            });
            this.list = arr;
            console.log(this.list);
          } else {
            this.list = [];
          }
          this.useInfos();
        } else {
          this.list = this.list2;
        }
      }
    },
    handleTonotice() {
      this.$router.push("/reservation");
    },
    click(i, router) {
      sessionStorage.setItem("activeId", i);
      this.active = i;
      this.$router.push(router);
    },
    command(command) {
      if (command == "2") {
        this.handleExit();
      } else if (command == "1") {
        this.pwdObj.flag = true;
      }
    },
    handleExit() {
      this.$confirm("是否退出登录?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出登录成功!",
          });
          localStorage.clear();
          this.$router.push("/login");
        })
        .catch(() => {});
    },
  },
  watch: {
    $route: {
      handler() {
        if (localStorage.getItem("token")) {
          this.useInfos();
        }
      },
    },
  },
};
</script>

<style lang="less" scoped>
.head-img {
  width: 1.3vw;
  height: 1.972vw;
}

.header-title {
  width: 15.63vw;
  height: 6.782vh;
  line-height: 6.782vh;
  text-align: center;
  background-color: white;
  color: black;
  font-weight: bold;
  font-size: 1.2vw;
  // border: 0.0789vh solid black;
}

.header {
  width: 100vw;
  height: 4.479vw;
  background-color: #fff;
  background-image: url('@/assets/images/shuiyin.png');
  // margin-bottom: 0.7886vh;
  display: flex;
  align-items: center;
  box-shadow: 0.315vh 0 0.789vh 0 #f3f2f2;

  .log {
    width: 15.625vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .img1 {
      width: 8.333vw;
      height: 2.552vw;
    }
  }

  .head-l {
    margin-left: 3vw;
    display: flex;
    justify-content: space-around;
  }

  .head-l1 {
    min-width: 6.93vw;
    // width: 6vw;
    height: 1.893vh;
    text-align: center;
    font-size: 0.83vw;
    color: #333333;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    // margin-right: 95px;

    img {
      width: 1.302vw;
      height: 1.302vw;
    }

    span {
      // width: 95px;
      min-width: 5vw;
      height: 1.814vh;
      font-family: MicrosoftYaHei;
      font-size: 1vw;
      font-weight: normal;
      font-stretch: normal;
      line-height: 1.814vh;
      letter-spacing: 0.05vw;
      color: #333333;
      margin-left: 0.52vw;
      text-align: left;
    }
  }

  .head-l1act {
    // color: #2c72ff;
    font-weight: bold;

    span {
      color: #2c72ff;
    }
  }

  .head-c {
    padding: 0.473vh 0.52vw;
    border-radius: 1.563vw;
    color: #ff0000;
    border: 0.0789vh solid #ff0000;
    margin-left: 2.604vw;
  }

  .head-r {
    width: 1.979vw;
    height: 1.979vw;
    margin-right: 0.521vw;
  }

  .img2 {
    margin-left: 0.52vw;
    margin-right: 3.646vw;
  }

  .header-r {
    margin-left: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 0.83vw;

    div {
      font-size: 1vw;
    }
  }
}
</style>
<style lang="less">
.el-dropdown-menu {
  min-width: 7.29vw;
  text-align: center;
}
</style>
<style lang="less">
.head-tongzhi {
  width: 1.094vw;
  height: 1.094vw;
  margin-right: 0.521vw;
  cursor: pointer;

  img {
    width: 1.094vw;
    height: 1.094vw;
  }
}

.el-dropdown {
  display: flex;
  justify-content: center;
}

.el-dropdown-link {
  img {
    width: 1.41vw;
    height: 0.473vh;
  }
}

.el-dropdown-menu {
  padding: 0.789vh 0 !important;
  margin: 0.394vh 0 !important;
  border: 0.052vw solid #ebeef5 !important;
  border-radius: 0.21vw !important;
  box-shadow: 0 0.1vw 0.946vh 0 rgba(0, 0, 0, 0.1) !important;

  .el-dropdown-menu__item {
    line-height: 2.839vh;
    font-size: 0.73vw !important;
    padding: 0.541vh 1.042vw !important;

    /deep/img {
      max-width: 4.583vw !important;
      max-height: 1.574vh !important;
    }
  }
}
</style>