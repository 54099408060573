<template>
  <div class="order-mask" v-if="data.flag">
    <!-- 新增 填写邀请码 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div class="mask-title">
          <!-- <div></div> -->
          {{ data.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <el-select v-model="textarea" placeholder="请选择所属门店">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.storename"
            :value="item.id"
          >
          </el-option>
        </el-select>
        <div class="mask-footer">
          <button v-if="data.btnq" @click="handleRefund">
            {{ data.btnq }}
          </button>
          <button v-if="data.btns" class="btn-bg" @click="handleSubmit">
            {{ data.btns }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
export default {
  name: "InvitationCode",
  props: ["data"],
  data() {
    return {
      textarea: "",
      radio: 0,
      codestatus: false,
      options:[]
    };
  },
  mounted() {
    console.log(this.data);
  },
  methods: {
    async childGetstore(){
      let res = await this.$api.childGetstore();
      if (res.code==1) {
        this.options= res.data;
      }
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        negativeFlag: false,
        submitFlag: false,
      };
      this.$emit("invitationCall", obj);
    },
    handleSubmit() {
      let obj = {
        negativeFlag: false,
        submitFlag: true,
        store_id: this.textarea,
      };
      
      this.$emit("invitationCall", obj);
    },
  },
  watch: {
    data: {
      deep: true,
      handler(newValue, oldValue) {
        this.childGetstore()
      },
    },
  },
};
</script>
      
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/.table {
  
  .el-select {
    width: 100%;
  }
  textarea {
    height: 28.391vh;
    resize: none;
  }

  .el-radio-group {
    text-align: left;
    padding-left: 2.76vw;

    .el-radio__label {
      font-size: 0.83vw;
    }
  }
}

.mask-main {
  width: 29.17vw !important;
  // height: 270px !important;
}

.el-input__inner {
  width: 26.042vw !important;
  height: 4.416vh !important;
}

.save {
  margin: 0 !important;
}

.table > div:nth-child(2) {
  font-family: MicrosoftYaHei;
  font-size: 0.73vw;
  color: #ff0000;
  margin-top: 2.71vh;
}

.table > div:nth-child(3) {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 4.574vh;
}

.order-mask .mask-main .mask-nav {
  padding-bottom: 2.445vh;
  border-bottom: none;
}

.mask-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4.574vh;

  button {
    width: 7.81vw;
    height: 3.627vh;
    border-radius: 0.31vw;
    font-size: 0.73vw;
    cursor: pointer;
    background: #fff;
    border: solid 0.05vw #999999;
    color: #999999;
    margin-right: 3.13vw;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-bg {
    background: #6d79ff;
    border: 0.0789vh solid #6d79ff;
    color: #fff;
  }
}

/deep/.el-input__count-inner {
  font-size: 0.625vw;
  padding: 0 0.26vw;
}
</style>