<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <table>
          <tr>
            <td class="table-td xin2">门店名称</td>
            <td colspan="3">
              <input
                type="text"
                v-model="users.storename"
                placeholder="请输入门店名称"
              />
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">联系人</td>
            <td>
              <input
                type="text"
                v-model="users.name"
                placeholder="请输入联系人"
              />
            </td>
            <td class="table-td xin2">联系人电话</td>
            <td>
              <input
                type="number"
                v-model="users.phone"
                placeholder="请输入联系人电话"
              />
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">门店地址</td>
            <td colspan="3" class="sel">
              <el-cascader
                size="large"
                :options="optionsaddr"
                v-model="selectedOptions"
                placeholder="请选择门店地址"
                :props="{ label: 'label', value: 'label' }"
              >
              </el-cascader>
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">详细地址</td>
            <td colspan="3">
              <input
                type="text"
                v-model="users.detailaddr"
                placeholder="请输入详细地址"
              />
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">登录账号</td>
            <td>
              <input
                type="text"
                v-model="users.username"
                placeholder="请输入登录账号"
              />
            </td>
            <td class="table-td xin2">是否启用</td>
            <td>
              <div style="padding-left: 1.25vw">
                <el-switch v-model="status" active-color="#13ce66" inactive-color="#D7D6D6">
                </el-switch>
              </div>
            </td>
          </tr>
          <tr v-if="users.id">
            <td class="table-td xin2">登录密码</td>
            <td colspan="3">
              <input
                type="text"
                v-model="users.password"
                :placeholder="
                  dataVal.type == 'edit'
                    ? '默认为空，输入密码为重置密码'
                    : '限6-16个字母和数字'
                "
              />
            </td>
          </tr>
          <tr v-else>
            <td class="table-td xin2">登录密码</td>
            <td>
              <input
                type="text"
                v-model="users.password"
                :placeholder="
                  dataVal.type == 'edit'
                    ? '默认为空，输入密码为重置密码'
                    : '限6-16个字母和数字'
                "
              />
            </td>
            <td class="table-td xin2">确定密码</td>
            <td>
              <input
                type="text"
                v-model="reppassword"
                placeholder="请确认密码"
              />
            </td>
          </tr>
        </table>
        <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">
          {{ dataVal.btn }}
        </div>
      </div>
    </div>
  </div>
</template>
      
<script>
//引入element-china-area-data实现三级联动地址
import { regionData, pcTextArr } from "element-china-area-data";
export default {
  name: "AddAccount",
  props: ["dataVal"],
  data() {
    return {
      //选择所在城市
      optionsaddr: regionData,
      selectedOptions: [],
      reppassword: "",
      users: {
        username: "", //登录账户
        password: "", //密码
        storename: "", //门店名称
        storeaddr: "", //门店地址
        detailaddr: "", //门店详细地址
        name: "", //联系人姓名
        phone: "", //联系人电话
        status: "", //1 启用 0禁用
        id: "", //id 编辑携带
      },
      status: true,
      role_type: [],
      arerArr: [],
      urls: this.imgurl,
      //上传图片文件headers
      headers: {
        token: window.localStorage.getItem("token"),
      },
      qrcode: "",
    };
  },
  mounted() {},
  methods: {
    //头像上传
    handleAvatar(res, file) {
      // console.log(res,file);
      this.users.wechat_code = res.data.imgsrc;
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        submitFlag: false, //是否调用接口
      };
      this.$emit("addAccountCell", obj);
    },
    async handleSubmit() {
      // if (this.users.name == "") {
      //   this.$message({
      //     message: "请输入用户名",
      //     type: "error",
      //   });
      //   return false;
      // }
      this.users.storeaddr = this.selectedOptions.join("/");
      this.users.status = this.status ? 1 : 0;
      let res = await this.$api.adminEdit({
        ...this.users,
      });
      if (res.code == 1) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      } else {
        return false;
      }
      let obj = {
        flag: false,
        submitFlag: true, //是否调用接口
        users: this.users,
        qrcode: this.qrcode,
      };
      this.$emit("addAccountCell", obj);
    },
  },
  created() {

  },
  watch: {
    dataVal: {
      handler(newVal, oldVal) {
        // console.log(newVal, '这样也可以得到数据~~~');
        if (newVal.type == "edit") {
          this.users = { ...newVal.item };
          this.users.password = "";
          this.selectedOptions = this.users.storeaddr.split("/")
          this.status = this.users.status == "1" ? true : false;
        } else if (newVal.type == "add") {
          this.reppassword = "";
          this.users = {
            username: "", //登录账户
            password: "", //密码
            storename: "", //门店名称
            storeaddr: "", //门店地址
            detailaddr: "", //门店详细地址
            name: "", //联系人姓名
            phone: "", //联系人电话
            status: "", //1 启用 0禁用
            id: "", //id 编辑携带
          };
          this.status = true;
          this.selectedOptions = [];
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="less">
.sel {
  .el-cascader {
    width: 100%;
  }
  .el-input__inner {
    height: 4.259vh;
    border: none;
    width: 100%;
    font-size: 0.83vw;
    color: #000;
    padding-left: 1.041vw;
  }
}
</style>
<style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/ .upload-demo {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
    height: 13.407vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    .avater-img {
      width: auto;
      height: 100%;
    }

    .avatar_i {
      width: 3.802vw;
      height: 3.7vw;
    }
  }
}

.flexDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.781vw;
  padding-left: 1.2vw;

  .flexDiv-right {
    display: flex;
    align-items: center;
  }

  .flexDiv-start {
    font-size: 0.73vw;
    color: #333;
  }

  .flexDiv-center {
    height: 1.104vh;
    width: 0.052vw;
    background-color: #aaaaaa;
    margin: 0 1.042vw;
  }

  .flexDiv-end {
    font-size: 0.73vw;
    color: #6d79ff;
    cursor: pointer;
  }
}

.order-mask .mask-main {
  width: 50vw;
  padding: 0;
  border-radius: 1.041vw;
  overflow: hidden;
}

.table table {
  width: 100%;
  margin-top: 1.577vh;
}

.order-mask .mask-main .mask-nav {
  padding: 2.366vh 1.562vw;
  background-color: #f8f8f8;
  margin-bottom: 1.498vh;
}

.order-mask .mask-main .table {
  padding: 0 1.562vw 2.366vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.83vw;
}

.table table .table-td {
  font-weight: bold;
  color: #333;
}

.table /deep/ .el-input--suffix .el-input__inner {
  padding-left: 1.25vw !important;
}

.qrcode {
  width: 10.42vw;
  height: 15.773vh;
  margin: 3.549vh auto 0;
}
</style>