<template>
  <!-- 批量审核通过 -->
  <div class="order-mask" v-if="dataVal.flag">
    <div class="mask-main">
      <div class="mask-nav mask-nav-h">
        <div></div>
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <!-- 审核通过 -->

      <div class="nav-search">
        <!-- 搜索框 -->
        <el-input v-model="keyword" style="width: 18vw" class="search_inp" placeholder="请输入通告标签" clearable></el-input>
        <el-button class="searchBtn" @click="handleSearch" type="primary">查询</el-button>
      </div>
      <div class="list">
        <div class="list_left">
          <p class="listleftp">已选</p>
        </div>
        <div class="list_right">
          <div class="list_lists">
            <p v-for="(item, i) in checklist" :key="item.id">
              {{ item.values }}
              <i @click="handleCheckdelete(i)" class="el-tag__close el-icon-close"></i>
            </p>
          </div>
        </div>
      </div>
      <div class="mask-nav">
        <div class="mask-title">
          <div></div>
          通告标签
        </div>
        <i></i>
      </div>
      <div class="labellist">
        <p v-for="item in labellist" @click="handleCheckLable(item)" :key="item.id"
          :class="[checkId.indexOf(item.id) != -1 ? 'listleftp2' : '']">
          {{ item.values }}
        </p>
      </div>
      <div class="mask-footer">
        <button v-if="dataVal.btnq" @click="handleRefund">
          {{ dataVal.btnq }}
        </button>
        <button v-if="dataVal.btns" class="btn-bg" @click="handleSubmit">
          {{ dataVal.btns }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Addexamine",
  props: ["dataVal"],
  data() {
    return {
      keyword: "",
      labellist: [],
      checklist: [],
      checkId: [],
      textarea: "",
      id: "",
    };
  },
  mounted() {
    // console.log('ssssssssss',this.data);
  },
  methods: {
    handleCheckLable(item) {
      if (this.checkId.indexOf(item.id) == -1) {
        this.checkId.push(item.id);
        this.checklist.push(item);
      }

      // console.log();
    },
    //搜索
    handleSearch() {
      this.dictionaryGetlabel();
    },
    // 删除已选便签
    handleCheckdelete(i) {
      this.checkId.splice(i, 1);
      this.checklist.splice(i, 1);
    },
    //取消弹窗
    handleRefund() {
      let data = {
        flag: false,
        submitFlag: false,
      };
      // this.checklist = [];
      // this.checkId = [];
      this.$emit("toexamineCall", data);
    },
    handleSubmit() {
      // if (this.checkId.length == []) {
      //   this.$message({
      //     message: "请选择首推标签",
      //     type: "error",
      //   });
      //   return false;
      // }
      let data = {
        flag: false, //弹窗是否显示
        submitFlag: true, //是否调用接口
        checkId: this.checkId,
      };
      // this.checklist = [];
      // this.checkId = [];
      this.$emit("toexamineCall", data);
    },
    //得到首推标签
    async dictionaryGetlabel() {
      let res = await this.$api.dictionaryGetlabel({
        keyword: this.keyword,
      });
      if (res.code == 1) {
        this.labellist = res.data;
      }
    },
  },
  watch: {
    dataVal: {
      deep: true,
      handler(newVal, oldvalue) {
        console.log(newVal);
        // this.id = newvalue.id;
        this.dictionaryGetlabel();
        if (newVal.edit) {
          this.checkId = newVal.checkId;
          this.checklist = newVal.checklist;
        }
      },
    },
  },
};
</script>
<style lang="less" scoped>
@import url("@/assets/css/mask.less");
@import url("@/assets/css/table.css");
@import url("@/assets/css/reservation/telement.less");

.mask-nav-h {
  width: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  border-radius: 0.789vh 0.521vw 0 0;
  padding-right: 1.25vw;
  margin-top: 0 !important;
}

.labellist p {
  cursor: pointer;
  padding: 2px 10px;
}

.order-mask .mask-main {
  width: 62.5vw;
  padding-top: 6.309vh;
  position: relative;
}

.nav-search {
  margin: 0.789vh 0 1.577vh 0;
  display: flex;
  align-items: center
}

.search_inp {
  height: 3.627vh;
  background-color: #ffffff;
  border-radius: 0.31vw;
  border: #e1e1e1;
  margin: 0 0.521vw 0 0;
}

.searchBtn {
  width: 6.25vw;
  height: 3.154vh;
  background-color: #6d79ff;
  border-radius: 0vw;
  font-family: MicrosoftYaHei;
  font-size: 0.89vw;
  line-height: 2.364vh;
  padding: 0;
  color: #ffffff;
  border-radius: 0.31vw;
}

.clear {
  border: 0.0789vh solid #6d79ff !important;
  background-color: white !important;
  color: #6d79ff !important;
}

.mask-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.76vh;

  button {
    width: 7.81vw;
    height: 3.627vh;
    border-radius: 0.31vw;
    font-size: 0.73vw;
    cursor: pointer;
    background: #fff;
    border: solid 0.05vw #999999;
    color: #999999;
    margin-right: 3.13vw;
  }

  button:last-child {
    margin-right: 0;
  }

  .btn-bg {
    background: #6d79ff;
    border: 0.0789vh solid #6d79ff;
    color: #fff;
  }
}
</style>
<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.list_left {
  width: 6.25vw;
  // background-color: #f8f8f8;

  .listleftp {
    color: black;
  }

  p {
    height: 4.732vh;
    line-height: 4.732vh;
    text-align: center;
    font-family: MicrosoftYaHei;
    font-size: 0.73vw;
    letter-spacing: 0.05vw;
    color: #6d79ff;
    margin: 0;
    // cursor: pointer;
  }
}

.list_right {
  flex: 1;
  margin-left: 1.562vw;

  .list_title {
    // width: 100%;
    height: 4.732vh;
    line-height: 4.732vh;
    font-size: 0.73vw;
    font-family: MicrosoftYaHei;
    color: #333333;
  }

  .list_lists {
    // max-width: 53.65vw;
    // height: 4.732vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    p {
      min-width: 5.73vw;
      height: 3.627vh;
      line-height: 3.627vh;
      text-align: center;
      font-family: MicrosoftYaHei;
      font-size: 0.73vw;
      color: #ffffff;
      background-color: #6d79ff;
      border-radius: 0.52vw;
      margin: 0.789vh 0.521vw 0 0;
      display: flex;
      justify-content: space-evenly;
      padding: 2px 10px;
    }

    .list_p {
      background-color: #f8f8f8;
      color: #666666;
      cursor: pointer;
    }

    i {
      line-height: 3.627vh;
      cursor: pointer;
    }
  }
}

// .order-mask{
//     border-radius: 0.816vw;
//     .mask-main{
//         padding: 0 5px  35px 5px;
//     }
//     div{
//         padding: 0 1.562vw;
//     }
// }

.order-mask .mask-main .mask-nav {
  margin-top: 0.946vh;
  padding-top: 1.814vh;
  border: 0;
  background-color: #f8f8f8 !important;
  padding-left: 1.25vw;
}

.labellist {
  display: flex;
  flex-wrap: wrap;
  max-width: 62.5vw;
  background-color: #f8f8f8;
  padding: 0.521vw;

  p {
    min-width: 6.25vw;
    height: 3.627vh;
    border-radius: 0.52vw;
    font-size: 0.73vw;

    background-color: #ffffff;
    color: #666666;
    line-height: 3.627vh;
    text-align: center;
    margin: 0 0.52vw;
    margin: 0.26vw;
  }

  .btn_p {
    color: #ffffff;
    background-color: #6d79ff;
  }

  .listleftp2 {
    color: #fff;
    background-color: #6d79ff;
  }
}

/deep/.table {
  padding-top: 1.577vh;

  textarea {
    width: 49.796vw;
    height: 24.49vw;
    resize: none;
  }
}
</style>