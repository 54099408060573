<template>
  <div class="order-mask" v-if="dataVal.flag">
    <!-- 上传底片 -->
    <div class="mask-main">
      <div class="mask-nav">
        <div></div>
        <div class="mask-title">
          <!-- <div></div> -->
          {{ dataVal.title }}
        </div>
        <i @click="handleRefund" class="el-icon-close"></i>
      </div>
      <div class="table">
        <table>
          <tr>
            <td class="table-td xin2">交付产品</td>
            <td colspan="3" class="sel">
              <el-select v-model="users.product" placeholder="请选择交付产品">
                <el-option
                  v-for="item in dicSrcArr"
                  :key="item.id"
                  :label="item.values"
                  :value="item.values"
                >
                </el-option>
              </el-select>
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">交付期限</td>
            <td colspan="3" class="sel">
              <el-date-picker
                v-model="givetime"
                type="date"
                value-format="timestamp"
                placeholder="点击选择交付期限"
              >
              </el-date-picker>
            </td>
          </tr>
          <tr>
            <td class="table-td xin2">是否交付</td>
            <td colspan="3">
              <div style="padding-left: 1.25vw">
                <el-switch
                  v-model="status"
                  active-color="#13ce66"
                  inactive-color="#D7D6D6"
                >
                </el-switch>
              </div>
            </td>
          </tr>
        </table>
        <div v-if="dataVal.btn != ''" class="save" @click="handleSubmit">
          {{ dataVal.btn }}
        </div>
      </div>
    </div>
  </div>
</template>
          
    <script>
export default {
  name: "AddAccount",
  props: ["dataVal"],
  data() {
    return {
      users: {
        model_id: "", //所属童模
        product: "", //产品
        givetime: "", //交付期限
        is_give: "", //是否交付 0否 1是
        id: "", //id编辑必填
      },
      status: false,
      urls: this.imgurl,
      givetime: "",
      dicSrcArr: [],
    };
  },
  mounted() {},
  methods: {
    //得到通告来源
    async dictionaryGetproduct() {
      let res = await this.$api.dictionaryGetproduct();
      if (res.code == 1) {
        this.dicSrcArr = res.data;
      }
    },
    //取消弹窗
    handleRefund() {
      let obj = {
        flag: false,
        submitFlag: false, //是否调用接口
      };
      this.$emit("addProductDeliveredCell", obj);
    },
    async handleSubmit() {
      // if (this.users.name == "") {
      //   this.$message({
      //     message: "请输入用户名",
      //     type: "error",
      //   });
      //   return false;
      // }
      if (this.givetime) {
        this.users.givetime = this.givetime / 1000;
      }
      this.users.is_give = this.status ? 1 : 0;
      let res = await this.$api.deliverEdit({
        ...this.users,
      });
      if (res.code == 1) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      } else {
        return false;
      }
      let obj = {
        flag: false,
        submitFlag: true, //是否调用接口
      };
      this.$emit("addProductDeliveredCell", obj);
    },
  },
  created() {
    this.dictionaryGetproduct();
  },
  watch: {
    dataVal: {
      handler(newVal, oldVal) {
        // console.log(newVal, '这样也可以得到数据~~~');
        if (newVal.type == "edit") {
          this.users = { ...newVal.item };
          this.givetime = this.users.givetime * 1000;
          this.status = this.users.status == "1" ? true : false;
        } else if (newVal.type == "add") {
          this.users = {
            model_id: newVal.model_id, //所属童模
            product: "", //产品
            givetime: "", //交付期限
            is_give: "", //是否交付 0否 1是
            id: "", //id编辑必填
          };
          this.givetime = "";
        }
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
    <style lang="less">
.sel {
  .el-cascader {
    width: 100%;
  }
  .el-input__inner {
    height: 4.259vh;
    border: none;
    width: 100%;
    font-size: 0.83vw;
    color: #000;
    padding-left: 1.041vw;
  }
  .el-select {
    width: 100%;
  }
  .el-input__prefix {
    display: none;
  }
  .el-textarea {
    margin-top: 0;
    border: none;
  }
  .el-textarea__inner {
    border: none;
    width: 100%;
    font-size: 0.83vw !important;
    color: #000;
  }
}
</style>
    <style lang="less" scoped>
@import url("@/assets/css/mask.less");

/deep/ .upload-demo {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-upload {
  width: 100%;

  .el-upload-dragger {
    width: 100%;
    height: 13.407vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    .avater-img {
      width: auto;
      height: 100%;
    }

    .avatar_i {
      width: 3.802vw;
      height: 3.7vw;
    }
  }
}

.flexDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.781vw;
  padding-left: 1.2vw;

  .flexDiv-right {
    display: flex;
    align-items: center;
  }

  .flexDiv-start {
    font-size: 0.73vw;
    color: #333;
  }

  .flexDiv-center {
    height: 1.104vh;
    width: 0.052vw;
    background-color: #aaaaaa;
    margin: 0 1.042vw;
  }

  .flexDiv-end {
    font-size: 0.73vw;
    color: #6d79ff;
    cursor: pointer;
  }
}

.order-mask .mask-main {
  width: 30vw;
  padding: 0;
  border-radius: 1.041vw;
  overflow: hidden;
}

.table table {
  width: 100%;
  margin-top: 1.577vh;
}

.order-mask .mask-main .mask-nav {
  padding: 1.366vh 1.562vw;
  background-color: #f8f8f8;
  margin-bottom: 1.498vh;
}

.order-mask .mask-main .table {
  padding: 0 1.562vw 2.366vh;
}

.table table input {
  padding-left: 1.25vw;
  font-size: 0.83vw;
}

.table table .table-td {
  font-weight: bold;
  color: #333;
}

.table /deep/ .el-input--suffix .el-input__inner {
  padding-left: 1.25vw !important;
}

.qrcode {
  width: 10.42vw;
  height: 15.773vh;
  margin: 3.549vh auto 0;
}
</style>